import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import SchemaDefinedEditor, { SchemaDefinedEditorProps } from "./SchemaDefinedEditor";

export function shouldDisplayInGrid(schema: any) {
  if (!schema.items) {
    return false;
  }
  if (schema.items.type !== "object") {
    return false;
  }

  if (!schema.items.properties) {
    return false;
  }

  const propSchemas = Object.values(schema.items.properties);
  return propSchemas.length < 5 && propSchemas.every((ps: any) => ps.type !== "object");
}

export default function GridEditor(props: SchemaDefinedEditorProps) {
  const columns: GridColDef<any>[] = Object.entries(props.schema.items.properties).map(([propName, _subSchema], idx) => {
    const res: GridColDef<any> = {
      field: propName,
      flex: idx === 0 ? 3 : 1,
      editable: true,
    };
    const subSchema = _subSchema as any;

    if (subSchema.type === "number" || subSchema.type === "integer") {
      res.type = "number";
    } else if (subSchema.type === "boolean") {
      res.type = "boolean";
    } else if (subSchema.enum) {
      res.type = "singleSelect";
    } else if (subSchema.type === "string") {
      res.type = "string";
    } else {
      res.renderCell = (params) => {
        return (
          <SchemaDefinedEditor
            hideLabel
            name={propName}
            readonly
            schema={subSchema}
            val={params.row[propName]}
            path={`${props.path}[${idx}].${propName}`}
            modified={props.modified}
            errors={props.errors}
            onChange={() => {}}
            selectedPath={props.selectedPath}
            onPathSelected={() => {}}
          />
        );
      };

      res.renderEditCell = (params) => {
        return (
          <SchemaDefinedEditor
            hideLabel
            name={propName}
            schema={subSchema}
            val={params.row[propName]}
            path={`${props.path}[${idx}].${propName}`}
            modified={props.modified}
            errors={props.errors}
            onChange={(newValue: any) => {
              const newItemArray = [...props.val];
              const newItem = { ...props.val[params.row._rowId] };
              newItemArray[params.row._rowId] = newItem;
              newItem[propName] = newValue;
              props.onChange(newItemArray);

              params.api.stopCellEditMode({ id: params.row._rowId, field: propName });
            }}
            selectedPath={props.selectedPath}
            onPathSelected={() => {}}
          />
        );
      };
    }

    return res;
  });

  const rows = (Array.isArray(props.val) ? props.val : []).map((r, idx) => ({
    ...r,
    _rowId: idx,
  }));

  return (
    <>
      <Typography variant="caption">{props.name}</Typography>
      <DataGrid
        rowSelection
        slots={{ toolbar: GridToolbar }}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        slotProps={{ toolbar: { showQuickFilter: true } }}
        columns={columns}
        sx={{ marginTop: 0, minHeight: 200 }}
        getRowId={(r) => r._rowId}
        rows={rows}
      />
    </>
  );
}
