import { DataItemReference } from "@mechination/data_validation";
import Chip from "@mui/material/Chip";
import LinkIcon from "@mui/icons-material/Link";
import { useNavigate, useParams } from "react-router-dom";

interface ItemReferenceChipProps {
  obj: DataItemReference | undefined;
}

export function ItemReferenceChip(props: ItemReferenceChipProps) {
  const { domainId } = useParams();
  const navigate = useNavigate();

  if (!props.obj) {
    return <Chip label="empty item ref" color="warning" variant="outlined" />;
  }

  return (
    <Chip
      label={props.obj.id}
      onDelete={() => {
        navigate(`/app/${domainId}/types/${props.obj!.type}/items/${props.obj!.id}`);
      }}
      deleteIcon={<LinkIcon />}
    />
  );
}
