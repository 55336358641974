import { SchemaObject } from "ajv";
import { FlatDataItem } from "./typedef";

export * from "./typedef";
export * from "./validator";
export * from "./DataInterface";

export function stripMeta(obj: object, ...keysToKeep: string[]) {
  return Object.fromEntries(Object.entries(obj).filter(([k, v]) => !k.startsWith("_") || keysToKeep.indexOf(k) !== -1));
}
