import AccordionDetails from "@mui/material/AccordionDetails";
import CircularProgress from "@mui/material/CircularProgress";
import { useCallback, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import env from "../env";
import { DataAPI, ItemReferenceResponse } from "@mechination/data_client";
import { Alert, Box, Button, Link, Tab, Tabs, Typography } from "@mui/material";
import { FlatDataItem } from "@mechination/data_validation";

interface ItemRefrencesProps {
  type: string;
  domain: string;
  itemId: string;
}

const api = new DataAPI(env.api.endpoint);

export default function ItemReferences(props: ItemRefrencesProps) {
  const [historyLoading, setHistoryLoading] = useState<boolean>(false);
  const [linkedItems, setLinkedItems] = useState<Record<string, FlatDataItem<any>[]>>({});
  const [selectedRefType, setSelectedRefType] = useState<number>(0);
  const [fetchError, setFetchError] = useState<string | undefined>(undefined);

  const loadReferences = useCallback(async () => {
    setHistoryLoading(true);
    setFetchError(undefined);
    try {
      const refs = await api.getItemReferences(props.domain, props.type, props.itemId);
      const byType: Record<string, ItemReferenceResponse<any>[]> = {};

      for (let i of refs) {
        let items = byType[i.item._type];
        if (!items) {
          byType[i.item._type] = [i];
        } else {
          items.push(i);
        }
      }
      for (let list of Object.values(byType)) {
        list.sort((a, b) => {
          const ai = a.item;
          const bi = b.item;
          let d = typeof ai.name == "string" && typeof bi.name === "string" ? ai.name.localeCompare(bi.name) : 0;
          if (d === 0) {
            d = ai._id - bi._id;
          }
          return d;
        });
      }
      setLinkedItems(byType);
      setSelectedRefType(0);
    } catch (ex: any) {
      setFetchError(ex.toString());
    } finally {
      setHistoryLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadReferences();
  }, [loadReferences]);

  const allTypes = Object.keys(linkedItems);

  return (
    <AccordionDetails>
      {historyLoading ? (
        <CircularProgress />
      ) : fetchError ? (
        <Alert severity="error">{fetchError}</Alert>
      ) : allTypes.length === 0 ? (
        <Typography variant="caption">
          No other data items link to this one. View data above to see which items this one links to
        </Typography>
      ) : (
        <div style={{ maxWidth: "100%", overflow: "hidden" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={selectedRefType}
              onChange={(evt, newVal) => {
                setSelectedRefType(newVal);
              }}
            >
              {allTypes.map((type) => {
                return <Tab label={type} key={type} />;
              })}
            </Tabs>
          </Box>

          <div role="tabpanel">
            <ol>
              {(Object.values(linkedItems)[selectedRefType] ?? []).map((ref, idx) => {
                const item = ref.item;
                return (
                  <li key={idx}>
                    <Link
                      component={RouterLink}
                      to={`/app/${encodeURIComponent(item._domain)}/types/${encodeURIComponent(item._type)}/items/${encodeURIComponent(item._id)}`}
                    >
                      {item.name ?? item._id}
                    </Link>{" "}
                    -{" "}
                    <Typography variant="body2" color="GrayText" component={"span"} fontSize={"60%"}>
                      {ref.paths.slice(0, 3).join(", ")} {ref.paths.length > 3 && <span>&hellip;</span>}
                    </Typography>
                  </li>
                );
              })}
            </ol>
          </div>

          {historyLoading ? (
            <CircularProgress />
          ) : (
            linkedItems.next && <Button onClick={() => loadReferences()}>fetch older events</Button>
          )}
        </div>
      )}
    </AccordionDetails>
  );
}
