import { Schema } from "ajv";

export type FlatDataItem<X> = X & {
  _id: string;
  _domain: string;
  _type: string;
  _etag: string;
  _modified_at: Date;
  _last_updater: string;
};

export interface DerivedIdScheme {
  from: string;
  lowercase?: boolean;
}

export type InlineSchema = string;

export type ItemValidation = {
  expr: string;
  message?: string;
};

export type DataItemAclAction = "create" | "read" | "modify" | "delete" | "list";
export type DataItemAclOutcome = "allow" | "deny";

export type DataItemAclEntry = {
  outcome?: DataItemAclOutcome;
  principals: string[];
  /**
   * If not specified, then all actions are allowed
   */
  actions?: DataItemAclAction[];
  conditions?: string[];
};

export interface DataItemType {
  name: string;
  description?: string;
  id_gen?: string;
  acl: DataItemAclEntry[];
  schema: InlineSchema;
  validation?: ItemValidation[];
  image?: AttachmentReference;
}

export type DataItemList<X> = FlatDataItem<X>[];

export interface DataItemReference {
  type: string;
  id: string;
}

export type AttachmentReference = DataItemReference & { type: "_att" };

export function isAttachmentReference(schema: any) {
  if (schema?.$ref !== "/schemas/itemref") {
    return false;
  }
  const allowedType = schema?.allowedType;
  if (Array.isArray(allowedType)) {
    return allowedType.find((t) => t.indexOf("/") > 0);
  }
  return allowedType.indexOf("/") > 0;
}

export function isImageAttachmentReference(schema: any) {
  if (!isAttachmentReference(schema)) {
    return false;
  }
  const allowedType = schema?.allowedType;
  if (Array.isArray(allowedType)) {
    return allowedType.find((t) => t.startsWith("image/"));
  }
  return allowedType.startsWith("image/");
}

export interface Measurement {
  value: number;
  unit: string;
}

export type DataItemDomain = {
  owner: string;
  name: string;
  display_order?: number;
  description?: string;
  image?: AttachmentReference;
  acl?: DataItemAclEntry[];
};

export type IdentityProvider = {
  kid: string;
  iss: string;
  public_key: string;
  sub?: string;
  aud?: string[];
  iat_limit?: string;
  cache?: boolean;
};

export type RegisteredSchema = {
  name: string;
  description?: string;
  schema: string;
};

export interface DataItemEventCommon {
  domain: string;
  type: string;
  id: string;
  ts: string;
  sub: string;
}

export interface DataAccessKey {
  kid: string;
  description?: string;
  public_key: string;
  sub: string;
  groups?: string[];
}

export interface DataItemInsertEvent extends DataItemEventCommon {
  event: "INSERT";
  item: any;
}

export interface DataItemModifyEvent extends DataItemEventCommon {
  event: "MODIFY";
  diff: any;
  item: any;
}

export interface DataItemRemoveEvent extends DataItemEventCommon {
  event: "REMOVE";
}

export type DataItemEvent = DataItemInsertEvent | DataItemModifyEvent | DataItemRemoveEvent;

/**
 * We hard code one schema for the entire system - That of a type.
 */
export const typeSchema: Schema = {
  $schema: "http://json-schema.org/draft-07/schema",
  $id: "/schemas/_type",
  description: "A description of a type that can be hosted, managed, and served by HumanData",
  type: "object",
  properties: {
    name: {
      type: "string",
      minLength: 1,
    },
    id_gen: {
      description:
        "Expression to  generate an ID for newly created items, based on the supplied item. If not specified, generateId() will be used",
      type: "string",
      format: "expression",
    },
    display_order: {
      type: "integer",
    },
    description: {
      description: "The description of this Type",
      type: "string",
    },
    image: {
      $ref: "/schemas/itemref",
      allowedType: "image/*",
      description:
        "An icon that represents this type.  Preferably a SVG or transparent PNG with a dark primary colour. This will be inverted where a light display is preferred (e.g. in Dark mode)",
    },
    schema: {
      type: "string",
      format: "schema",
      description: "The schema used for validating elements of this type - Can be an inline schema or a URL",
    },
    acl: { $ref: "/schemas/acl", description: "Specifies who is allowed to perform actions on items of this type" },
    webhooks: {
      type: "array",
      description: "A set of processors that the system will notify whenever items in this type are changed",
      items: {
        type: "object",
        properties: {
          target: { type: "string", description: "ARN of a Lambda or a https URL to which a POST request will be made" },
          token: {
            type: "string",
            description:
              "A Bearer token that will be included in any HTTP requests made - Is a form of security.  If value is in the form of arn:aws:secretsmanager:<SecretName> then the value will be looked up in Secrets Manager.",
          },
        },
        required: ["target"],
      },
    },
    validation: {
      description: "Additional rules used to validate items where JSONSchema is insufficient",
      type: "array",
      items: {
        type: "object",
        properties: {
          if: {
            type: "string",
            minLength: 1,
            description: "JMESPath expression which will cause validation to fail if it returns a truthy value",
          },
          error_message: { type: "string", description: "Message to dispaly to user when validation fails", minLength: 1 },
        },
        required: ["expr"],
      },
    },
  },
  additionalProperties: false,
  required: ["name", "acl", "schema"],
};

export const isFlatDataItem = (x: any): x is FlatDataItem<any> => x && typeof x === "object" && x._id && x._type && x._domain;
