import TextField from "@mui/material/TextField";
import { SchemaDefinedEditorProps } from "./SchemaDefinedEditor";
import { useMemo } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { isInError } from "../utils/errors";
import { Typography } from "@mui/material";

interface CurrencyEditorProps extends SchemaDefinedEditorProps {}

export default function CurrencyEditor(props: CurrencyEditorProps) {
  const { val, errors, path } = props;

  const error = useMemo(() => isInError(path, errors), [errors, path]);

  if (props.readonly) {
    return (
      <span style={{ textWrap: "nowrap" }}>
        <Typography component={"span"}>${val.currency}</Typography> <Typography component={"span"}>{val.value}</Typography>
      </span>
    );
  }

  return (
    <TextField
      variant="standard"
      type="number"
      InputProps={{
        startAdornment: <InputAdornment position="start">$AUD</InputAdornment>,
        slotProps: { input: { step: 0.05, min: 0 } },
      }}
      onChange={(evt) => {
        props.onChange({
          value: parseFloat(evt.target.value),
          currency: "AUD",
        });
      }}
      name={props.name}
      id={props.name}
      error={error !== undefined}
      label={props.hideLabel ? undefined : props.name}
      helperText={error ?? ""}
      value={val?.value.toString()}
    />
  );
}
