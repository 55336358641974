import TextField from "@mui/material/TextField";
import { SchemaDefinedEditorProps } from "./SchemaDefinedEditor";
import { useEffect, useMemo, useState } from "react";
import { isInError } from "../utils/errors";

interface IntegerEditorProps extends SchemaDefinedEditorProps {}

export default function IntegerEditor(props: IntegerEditorProps) {
  const { errors, path } = props;
  const [parseError, setParseError] = useState<string | undefined>();
  const [text, setText] = useState<string>("");

  useEffect(() => {
    setText(props.val?.toString());
  }, [props.val]);

  const error: string | undefined = useMemo(() => parseError || isInError(path, errors), [parseError, errors, path]);

  return (
    <TextField
      variant="standard"
      type="number"
      onChange={(evt) => {
        const newStr: string = evt.target.value;
        setText(newStr);
        try {
          if (newStr) {
            // ParseInt will happily return a number if there are trailing non-number values, so we need to test that the whole string is a valid integer.
            if (!newStr.match(/^-?[0-9]+$/)) {
              throw new Error("Not an integer");
            }
            // ParseInt will do radix conversion unless you specify the radix directly.
            const newInt = parseInt(newStr, 10);
            console.log(evt.target.value, newInt);
            if (Number.isNaN(newInt)) {
              throw new Error(`${evt.target.value} is not a valid integer`);
            }
            props.onChange(newInt);
          } else {
            props.onChange(undefined);
          }
          setParseError(undefined);
        } catch (ex: any) {
          props.onChange(undefined);
          setParseError(ex.message);
        }
      }}
      name={props.name}
      id={props.name}
      error={error !== undefined}
      label={props.hideLabel ? undefined : props.name}
      helperText={error ?? ""}
      value={text}
    />
  );
}
