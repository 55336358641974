import { DataAPI } from "@mechination/data_client";
import { DataItemReference, FlatDataItem } from "@mechination/data_validation";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Fragment, ReactNode, useEffect, useState } from "react";
import env from "../env";
import { ItemReferenceChip } from "./ItemReferenceChip";
import { Autocomplete, CircularProgress, IconButton, TextField } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { useNavigate, useParams } from "react-router-dom";

interface ItemSelectorProps {
  value: DataItemReference | undefined;
  types?: undefined | string[];
  readonly?: boolean;
  autoOpen?: boolean;
  hideLabel?: boolean;
  name: string;
  onChange: (newRef: DataItemReference) => any;
}

export default function ItemSelector(props: ItemSelectorProps) {
  const [possibleValues, setPossibleValues] = useState<FlatDataItem<unknown>[]>([]);
  const [open, setOpen] = useState(props.autoOpen ?? false);
  const loading = open && possibleValues.length === 0;
  const navigate = useNavigate();
  const { domainId } = useParams();

  useEffect(() => {
    const api = new DataAPI(env.api.endpoint);
    if (props.types) {
      if (loading) {
        // TODO only project names, images, and IDs - fall back to ID if name not specified (could also consult the type itself)
        Promise.all(props.types?.map((t) => api.listItems("catering", t)))
          .then((itemsFromTypes) =>
            itemsFromTypes.length === 1 ? itemsFromTypes[0] : ([] as FlatDataItem<any>[]).concat.apply([], itemsFromTypes)
          )
          .then((items) => {
            return setPossibleValues(items);
          });
      }
    } else {
      // is link to _any_ item!.   Make them specify.
      setPossibleValues([]);
    }
  }, [loading, props.types, props.readonly]);

  let editor: ReactNode;

  useEffect(() => {
    if (!open) {
      setPossibleValues([]);
    }
  }, [open]);

  function navigateToLink() {
    if (props.value) {
      navigate(`/app/${domainId}/types/${props.value.type}/items/${props.value.id}`);
    }
  }

  if (props.readonly) {
    editor = <ItemReferenceChip obj={props.value} />;
  } else {
    editor = (
      <Autocomplete
        sx={{ minWidth: 320 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        getOptionLabel={(option) => {
          return (option as any).name ?? option._id;
        }}
        options={possibleValues}
        loading={loading}
        disableClearable
        // renderOption={(props, option) => {
        //   return <span>{JSON.stringify(option)}</span>;
        // }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <IconButton
                  onClick={(evt) => {
                    evt.preventDefault();
                    navigateToLink();
                  }}
                >
                  <LinkIcon />
                </IconButton>
              ),
              endAdornment: (
                <Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
        value={
          props.value
            ? possibleValues.find((i) => i._id === props.value?.id) ??
              ({ _type: props.value?.type, _id: props.value?.id } as FlatDataItem<unknown>)
            : undefined
        }
        onChange={(evt, val) => props.onChange({ type: val._type, id: val._id })}
      />
    );
  }

  return props.hideLabel ? (
    editor
  ) : (
    <FormControl variant="standard" sx={{ minWidth: 320 }}>
      {props.name && <InputLabel id="demo-simple-select-standard-label">{props.name}</InputLabel>}
      {editor}
    </FormControl>
  );
}
