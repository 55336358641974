import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { SchemaDefinedEditorProps } from "./SchemaDefinedEditor";

export default function BoolEditor(props: SchemaDefinedEditorProps) {
  const editor = (
    <Checkbox
      id={props.name}
      checked={props.val}
      onChange={(evt) => {
        props.onChange(evt.target.checked);
      }}
    />
  );

  return props.hideLabel ? editor : <FormControlLabel control={editor} label={props.name} />;
}
