import { ReactNode } from "react";
import ImageEditor from "./ImageEditor";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import BoolEditor from "./BoolEditor";
import ItemRefEditor from "./ItemRefEditor";
import ListEditor from "./ListEditor";
import ObjectEditor from "./ObjectEditor";
import StringEditor from "./StringEditor";
import MeasurementEditor from "./MeasurementEditor";
import { Marker } from "../pages/ItemEditor";
import IntegerEditor from "./IntegerEditor";
import EnumEditor from "./EnumEditor";
import CurrencyEditor from "./CurrencyEditor";
import AclEditor from "./AclEditor";
import EmailSchemaEditor from "./EmailSchemaEditor";
import { isImageAttachmentReference } from "@mechination/data_validation";
import GridEditor, { shouldDisplayInGrid } from "./GridEditor";

export type Schema = any;

export interface SchemaDefinedEditorProps {
  schema: Schema;
  path: string;
  description?: string;
  modified: boolean;
  title?: ReactNode;
  name: string;
  hideLabel?: boolean;
  readonly?: boolean;
  val: any;
  errors: Marker[];
  onChange: (newValue: any) => void;

  selectedPath: string | undefined;
  onPathSelected: (newPath: string) => any;
}

export default function SchemaDefinedEditor(props: SchemaDefinedEditorProps) {
  const schema = props.schema;

  if (schema.$ref === "/schemas/itemref") {
    if (isImageAttachmentReference(schema)) {
      return <ImageEditor {...props} />;
    }
    const allowedTypes: string[] | undefined = schema.allowedType
      ? typeof schema.allowedType == "string"
        ? [schema.allowedType]
        : schema.allowedType
      : undefined;
    return <ItemRefEditor {...props} domain={"catering"} types={allowedTypes} />;
  }
  const type = schema.type ?? schema.$ref;
  if (!type) {
    return <Alert severity="warning">No Type for schema {JSON.stringify(schema)}</Alert>;
  }

  switch (type) {
    case "boolean":
      return <BoolEditor {...props} />;

    case "object":
      return <ObjectEditor {...props} />;

    case "array":
      if (shouldDisplayInGrid(schema)) {
        return <GridEditor {...props} />;
      }
      return <ListEditor {...props} />;

    case "/schemas/acl":
      return <AclEditor {...props} />;

    case "/schemas/measurement":
      return <MeasurementEditor {...props} />;

    case "/schemas/monetary_value":
      return <CurrencyEditor {...props} />;
    case "string":
      if (schema.enum) {
        return <EnumEditor {...props} />;
      }
      if (schema.format === "email") {
        return <EmailSchemaEditor {...props} />;
      }
      return <StringEditor {...props} multiline={schema.format === "multiline" || schema.format === "schema"} />;
    case "integer":
      return <IntegerEditor {...props} />;

    default:
      return (
        <Grid xs={12}>
          <Alert severity="warning">
            {props.name}: Unsupported data type '{type}'
          </Alert>
        </Grid>
      );
  }
}
