import Alert from "@mui/material/Alert";
import SchemaDefinedEditor, { SchemaDefinedEditorProps } from "./SchemaDefinedEditor";
import Button from "@mui/material/Button";
import AlertTitle from "@mui/material/AlertTitle";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Summary from "./Summary";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Stack from "@mui/material/Stack";
import { isInError } from "../utils/errors";

export default function ListEditor(props: SchemaDefinedEditorProps) {
  const { schema, val: arr } = props;

  const [selectedPaths, setSelectedPaths] = useState<Set<number>>(new Set());

  const itemSchema = schema.items;

  // Ways that it can go
  // 1. one of links to many types.  - Show as chips with a selector at the top.
  // 1. links to one type. - Chips with a selector.
  // 1. objects - show in a table.
  // 1. Strings[enum] - Show as a chips with a selector at the top.
  // 1. Images - show as a gallery
  // 1. Booleans - list of editors, with selection. A two  column table (index, value)?
  // 1. Numbers - list of editors.
  // 2. Strings - list of editors.

  // console.log("Rendering List", props);

  if (!arr) {
    return (
      <Alert severity="info" action={<Button onClick={() => props.onChange([])}>Add</Button>}>
        <AlertTitle>{props.name}</AlertTitle>
        Not present
      </Alert>
    );
  }

  function updateListItem(newItem: any, idx: number) {
    const newArr = [...arr];
    newArr[idx] = newItem;
    props.onChange(newArr);
  }

  function addSelection(idx: number) {
    const newSet = new Set(selectedPaths);
    newSet.add(idx);
    setSelectedPaths(newSet);
  }

  function removeSelection(idx: number) {
    const newSet = new Set(selectedPaths);
    newSet.delete(idx);
    setSelectedPaths(newSet);
  }

  function appendItem() {
    let newItem: any;
    if (itemSchema.type === "string") {
      newItem = "";
    } else if (itemSchema.type === "number" || itemSchema.type === "integer") {
      newItem = 0;
    } else if (itemSchema.type === "array") {
      newItem = [];
    } else {
      newItem = {};
    }

    props.onChange([...arr, newItem]);
    props.onPathSelected(`${props.path}[${arr.length}]`);
  }

  function removeSelected() {
    // Sort them in reverse order so that we remove the highest one first (to avoid messing with indexes)
    const newArr = [...arr];
    for (const idx of Array.from(selectedPaths).sort((a, b) => b - a)) {
      newArr.splice(idx, 1);
    }
    props.onChange(newArr);
    setSelectedPaths(new Set());
  }

  const itemViews = arr.map((item: any, idx: number) => {
    const itemPath = `${props.path}[${idx}]`;
    const isLeaf = itemSchema.type === "string" || itemSchema?.$ref === "/schemas/itemref";
    const expanded = props.selectedPath === itemPath || props.selectedPath?.startsWith(itemPath + ".");

    const error: string | undefined = isInError(itemPath, props.errors);

    const editor = (
      <SchemaDefinedEditor
        schema={itemSchema}
        path={itemPath}
        name={""}
        modified={props.modified}
        val={item}
        errors={props.errors}
        onChange={(newVal) => updateListItem(newVal, idx)}
        selectedPath={props.selectedPath}
        onPathSelected={props.onPathSelected}
      />
    );

    return (
      <ListItem key={idx}>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={selectedPaths.has(idx)}
            onChange={(evt) => (evt.target.checked ? addSelection(idx) : removeSelection(idx))}
            tabIndex={-1}
            disableRipple
            // inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText disableTypography className={error ? "hudatalisteditor error" : "hudatalisteditor"}>
          {isLeaf ? (
            editor
          ) : (
            <Accordion
              sx={{ maxWidth: "100%" }}
              expanded={expanded}
              onChange={(evt) => {
                props.onPathSelected(expanded ? "$" : itemPath);
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />} sx={{ color: error ? "" : "inherit" }}>
                {expanded ? (
                  <Typography variant="caption">{itemPath.substring(2)}</Typography>
                ) : (
                  <Summary obj={item} schema={itemSchema}></Summary>
                )}
              </AccordionSummary>
              <AccordionDetails>{expanded && editor}</AccordionDetails>
            </Accordion>
          )}
        </ListItemText>
      </ListItem>
    );
  });

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="caption" sx={{}}>
          {props.name} - <em style={{ fontWeight: 100 }}>{arr.length} items</em>
        </Typography>

        <IconButton size="small" onClick={appendItem}>
          <AddIcon />
        </IconButton>
        <IconButton size="small" disabled={selectedPaths.size === 0} onClick={removeSelected}>
          <DeleteIcon />
        </IconButton>
      </Stack>
      <List sx={{ maxWidth: "100%" }} dense>
        {itemViews}
      </List>
    </Box>
  );
}
