import TextField from "@mui/material/TextField";
import { SchemaDefinedEditorProps } from "./SchemaDefinedEditor";
import { useMemo } from "react";
import { isInError } from "../utils/errors";

interface StringEditorProps extends SchemaDefinedEditorProps {
  width?: string | number;
  multiline?: boolean;
}

export default function StringEditor(props: StringEditorProps) {
  const { val, errors, path } = props;
  const error = useMemo(() => isInError(path, errors), [errors, path]);

  return (
    <TextField
      variant="standard"
      sx={{ width: props.width ?? "40em" }}
      onChange={(evt) => props.onChange(evt.target.value)}
      name={props.name}
      id={props.name}
      multiline={props.multiline}
      error={error !== undefined}
      label={props.hideLabel ? undefined : props.name}
      helperText={error ?? ""}
      value={val}
    />
  );
}
